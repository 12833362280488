export const lazyLoading = function () {
  const domContentLoading = () => {
    document.addEventListener('DOMContentLoaded', () => {
      setTimeout(() => {
        const elements = Array.from(document.querySelectorAll('.js-dom-loading')) || [];

        elements.forEach(elementItem => {
          const { lazyContent, lazyCallback } = elementItem.dataset;

          try {
            if (lazyContent) {
              elementItem.innerHTML = lazyContent;
            }

            if (lazyCallback) {
              window[lazyCallback]()
            }

            elementItem.classList.add('dom-loading--init');
          } catch (error) {
            console.error(`#domContentLoading: ${error}, element:`, elementItem);
          }
        })
      }, 2500);
    })
  }


  domContentLoading();
}
