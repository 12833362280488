import SwiperCore from 'swiper';
import { Navigation, Pagination, Autoplay, Thumbs } from 'swiper/modules';
SwiperCore.use([Navigation, Pagination, Autoplay, Thumbs]);

let feedbackSwiper;
let recommendSwiper;
let casesSwiper01;
let casesSwiper02;

export const initSliders = function () {
  feedbackSwiper = new SwiperCore('.feedback-swiper', {
    slidesPerView: 3,
    spaceBetween: 24,
    centeredSlides: false,
    loop: true,
    slideToClickedSlide: true,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
      1025: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
      1600: {
        slidesPerView: 3,
        spaceBetween: 24,
        loop: true,
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  recommendSwiper = new SwiperCore('#recommended-swiper', {
    slidesPerView: 3,
    spaceBetween: 24,
    centeredSlides: false,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
      1025: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
      1600: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
    },
    navigation: {
      nextEl: '#recommended-buttons .swiper-button-next',
      prevEl: '#recommended-buttons .swiper-button-prev',
    },
  });

  const casesParams = {
    spaceBetween: 16,
    a11y: false,
    freeMode: true,
    speed: 8000,
    loop: true,
    slidesPerView: "auto",
    slideToClickedSlide: false,
    centeredSlides: false,
    shortSwipes: false,
    longSwipes: false,
    // simulateTouch: true,
    // preloadImages: true,
    // watchSlidesProgress: true,
    // touchRatio: 1.5,
    // touchReleaseOnEdges: true,
    // edgeSwipeThreshold: 0,
  }

  casesSwiper01 = new SwiperCore('#cases-slider-01',
    Object.assign(casesParams, {
      autoplay: {
        delay: 500,
        enabled: true,
        pauseOnMouseEnter: false,
        disableOnInteraction: false,
      }
    })
  );

  casesSwiper02 = new SwiperCore('#cases-slider-02',
    Object.assign(casesParams, {
      autoplay: {
        delay: 500,
        enabled: true,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
        reverseDirection: true,
      }
    })
  );
};
