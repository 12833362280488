// Load Styles
import '../scss/main.scss';

import Masonry from 'masonry-layout';
import ticker from './ticker.js';
import header from './header.js';
import cardsRange from './cardsRange.js';
import { initSliders } from './slidersSwiper.js';
import { initSectionWelcome } from './welcome.js';
import { initSectionCasesSecondary } from './casesSecondary.js';
import { lazyLoading } from './lazyLoading.js';

// Load Bootstrap init
import { initBootstrap } from './bootstrap.js';

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
  modal: true,
});

header();
ticker();
cardsRange();
initSliders();
initSectionWelcome();
initSectionCasesSecondary();
lazyLoading();


document.addEventListener('DOMContentLoaded', () => {
  window.addEventListener('scroll', () => {
    if (window.scrollY > 0) {
      document.documentElement.classList.add('html-scroll-y');
    } else {
      document.documentElement.classList.remove('html-scroll-y');
    }
  });
})
