export const initSectionCasesSecondary = function () {
  const sectionElem = document.querySelector('.cases-secondary');
  const sectionModal = document.getElementById('casesSecondaryModal');

  const onSliderClick = function (event) {
    const { target } = event;
    const cardElem = target.closest('.cases-secondary__card');

    if (cardElem && cardElem.dataset.vimeoUrl) {
      if (sectionModal) {
        const newUrl = cardElem.dataset.vimeoUrl.split('/').pop();
        const iframeUrl = `https://player.vimeo.com/video/${newUrl}?autoplay=1`;
        const iframeElem = sectionModal.querySelector('iframe');

        iframeElem.src = iframeUrl;
        sectionModal.modal.show()
      }
    }

    event.preventDefault();
  }

  if (sectionElem) {
    const sliderElems = Array.from(sectionElem.querySelectorAll('.cases-secondary__slider'));

    if (sliderElems.length > 0) {
      sliderElems.forEach((el) =>
        el.addEventListener('click', onSliderClick, false)
      )
    }

    if (sectionModal) {
      sectionModal.addEventListener('hide.bs.modal', event => {
        const iframeElem = sectionModal.querySelector('iframe');

        iframeElem.src = '';
      })
    }
  }
}
