import anime from 'animejs/lib/anime.es.js';

function fitElementToParent(el, padding) {
  let timeout = null;

  function resize() {
    if (timeout) {
      clearTimeout(timeout)
    }

    anime.set(el, {scale: 1});

    const pad = padding || 0;
    const parentEl = el.parentNode;
    const elOffsetWidth = el.offsetWidth - pad;
    const parentOffsetWidth = parentEl.offsetWidth;
    const ratio = parentOffsetWidth / elOffsetWidth;

    timeout = setTimeout(anime.set(el, { scale: ratio }), 10);
  }

  resize();
  window.addEventListener('resize', resize);
}

export const initSectionWelcome = function () {
  const sectionElem = document.querySelector('.welcome');
  const animaionElem = document.querySelector('.welcome-animation');

  if (sectionElem && animaionElem) {
    const staggerVisualizerEl = animaionElem.querySelector('.welcome-animation__visualizer');
    const dotsWrapperEl = animaionElem.querySelector('.welcome-animation__wrapper');
    const dotsFragment = document.createDocumentFragment();
    const grid = [20, 10];
    const cell = 55;
    const numberOfElements = grid[0] * grid[1];

    let animation;
    let paused = true;

    fitElementToParent(staggerVisualizerEl, 0);

    for (let i = 0; i < numberOfElements; i++) {
      const dotEl = document.createElement('div');

      dotEl.classList.add('welcome-animation__dot');
      dotsFragment.appendChild(dotEl);
    }

    dotsWrapperEl.appendChild(dotsFragment);

    let nextIndex = 0;
    let index = anime.random(0, numberOfElements - 1);

    anime.set('.welcome-animation .welcome-animation__cursor', {
      translateX: anime.stagger(-cell, {grid: grid, from: index, axis: 'x'}),
      translateY: anime.stagger(-cell, {grid: grid, from: index, axis: 'y'}),
      translateZ: 0,
      scale: 1.5,
    });

    function play() {
      paused = false;

      if (animation) {
        animation.pause();
      }

      nextIndex = anime.random(0, numberOfElements - 1);

      animation = anime.timeline({
        easing: 'easeInOutQuad',
        complete: play
      })
        .add({
          targets: '.welcome-animation .welcome-animation__cursor',
          keyframes: [
            { scale: .75, duration: 120},
            { scale: 2.5, duration: 220},
            { scale: 1.5, duration: 450},
          ],
          duration: 300
        })
        .add({
          targets: '.welcome-animation .welcome-animation__dot',
          keyframes: [
            {
              translateX: anime.stagger('-2px', {grid: grid, from: index, axis: 'x'}),
              translateY: anime.stagger('-2px', {grid: grid, from: index, axis: 'y'}),
              duration: 100
            }, {
              translateX: anime.stagger('4px', {grid: grid, from: index, axis: 'x'}),
              translateY: anime.stagger('4px', {grid: grid, from: index, axis: 'y'}),
              scale: anime.stagger([2.6, 1], {grid: grid, from: index}),
              duration: 225
            }, {
              translateX: 0,
              translateY: 0,
              scale: 1,
              duration: 1200,
            }
          ],
          delay: anime.stagger(80, {grid: grid, from: index})
        }, 30)
        .add({
          targets: '.welcome-animation .welcome-animation__cursor',
          translateX: { value: anime.stagger(-cell, {grid: grid, from: nextIndex, axis: 'x'}) },
          translateY: { value: anime.stagger(-cell, {grid: grid, from: nextIndex, axis: 'y'}) },
          scale: 1.5,
          easing: 'cubicBezier(.075, .2, .165, 1)'
        }, '-=800')

      index = nextIndex;
    }

    play();
  }
}
